
import * as ipxRuntime$OtbBBQvDRV from '/www/frontend/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "modifiers": {
      "width": 250,
      "height": 250
    }
  },
  "provider": "ipx",
  "domains": [
    "www.espiker.com"
  ],
  "alias": {
    "/api": "https://api.espiker.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$OtbBBQvDRV, defaults: {} }
}
        